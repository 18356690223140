import React from 'react'

const ContactUs = () => (
  <div className="container is-mobile is-centered">
    <div>Instagram: <a href="https://www.instagram.com/optikbintang107/?hl=en">@optikbintang107</a></div>
    <div>Telpon: <a href="tel:021-3452338">3452338</a></div>
    <div>Whatsapp (Pesanan): <a href="tel:0857-1622-1016">0857-1622-1016</a></div>
  </div>
)

export default ContactUs