module.exports = {
  siteTitle: 'Optik Bintang', // Site title.
  siteTitleAlt: 'Optik Bintang', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://optikbintang.com', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'Cepat, Tepat dan Ramah', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: 'GTM-N4L3DGF', // GTM tracking ID.
  disqusShortname: '', // Disqus shortname.
  userName: 'Optik Bintang',
  userTwitter: '',
  userLocation: 'Jakarta, Indonesia',
  userDescription: '',
  copyright: 'Copyright © Optik Bintang. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}